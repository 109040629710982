import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const $ = window.$ = window.jQuery = require('jquery');
require('lightbox2');

// Snowflakes
// new Snowflakes({
//     color: "#9B191E",
//     count: 50
// });

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
